/* wellnessseekerdashboard.css */

/* -- Modern Pastel Palette --
   Primary Soft Green:    #b2ddb6
   Secondary Mint:        #aee6ac
   Accent Soft Teal:      #98ddca
   Text Dark Gray:        #2f2f2f
   Light Gray BG:         #f9fafc
   Error Red:             #d32f2f
   Card BG:               #ffffff
*/

.ws-dashboard-container {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    background-color: #f9fafc;
    font-family: 'Roboto', sans-serif;
    color: #2f2f2f;
    min-height: 100vh;
    box-sizing: border-box;
}

/* Header bar */
.ws-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(135deg, #b2ddb6, #aee6ac);
    padding: 1rem 1.5rem;
    border-radius: 8px;
    margin-bottom: 2rem;
    flex-wrap: wrap;
}

.ws-header-left h1 {
    margin: 0;
    font-size: 1.6rem;
    font-weight: 600;
    color: #2f2f2f;
}

.ws-header-nav {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}

.ws-nav-btn {
    background: linear-gradient(135deg, #b2ddb6, #aee6ac);
    color: #2f2f2f;
    font-weight: 600;
    border: none;
    border-radius: 20px;
    padding: 0.55rem 1.25rem;
    cursor: pointer;
    transition: box-shadow 0.3s ease, transform 0.2s ease;
    font-size: 0.95rem;
}

    .ws-nav-btn.active,
    .ws-nav-btn:hover {
        background-color: #98ddca;
        box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        transform: translateY(-2px);
    }

.ws-logout-button {
    background-color: #ffffff;
    border: none;
    border-radius: 20px;
    padding: 0.5rem 1rem;
    cursor: pointer;
    font-size: 0.9rem;
    font-weight: 500;
    color: #2f2f2f;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

    .ws-logout-button:hover {
        background-color: #98ddca;
        box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    }

/* Error messages */
.ws-error-message {
    color: #d32f2f;
    background-color: #ffebee;
    padding: 0.75rem 1rem;
    border-left: 4px solid #d32f2f;
    margin-bottom: 1.5rem;
    border-radius: 4px;
}

/* Tab Navigation */
.ws-tab-navigation {
    display: flex;
    gap: 1rem;
    margin-bottom: 2rem;
    flex-wrap: wrap;
}

    .ws-tab-navigation button {
        background: linear-gradient(135deg, #b2ddb6, #aee6ac);
        color: #2f2f2f;
        font-weight: 600;
        border: none;
        border-radius: 20px;
        padding: 0.55rem 1.25rem;
        cursor: pointer;
        transition: box-shadow 0.3s ease, transform 0.2s ease;
        font-size: 0.95rem;
    }

        .ws-tab-navigation button.active {
            background-color: #98ddca;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }

/* Filters area */
.ws-filters {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 2rem;
    background-color: #ffffff;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.05);
}

.ws-filter-group {
    display: flex;
    flex-direction: column;
    min-width: 160px;
}

    .ws-filter-group label {
        font-weight: 500;
        margin-bottom: 0.3rem;
        color: #2f2f2f;
    }

    .ws-filter-group input[type="text"],
    .ws-filter-group input[type="time"],
    .ws-filter-group select {
        background-color: #f4f6f8;
        border: 1px solid #ccc;
        border-radius: 6px;
        padding: 0.5rem;
        font-size: 0.9rem;
        color: #2f2f2f;
        transition: box-shadow 0.2s ease;
    }

        .ws-filter-group input:focus,
        .ws-filter-group select:focus {
            outline: none;
            border-color: #aee6ac;
            box-shadow: 0 0 0 3px rgba(174,230,172,0.2);
        }

/* Layout for Therapist search */
.ws-layout {
    display: flex;
    gap: 2rem;
}

.ws-sidebar-left {
    flex: 0 0 300px;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.ws-main-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

/* Therapist list section */
.ws-therapist-list {
    background-color: #ffffff;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.05);
}

    .ws-therapist-list h2 {
        margin-top: 0;
        margin-bottom: 1rem;
    }

/* Therapist card */
.ws-therapist-card {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 1rem;
    margin-bottom: 1rem;
    transition: box-shadow 0.3s;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

    .ws-therapist-card:hover {
        box-shadow: 0 6px 12px rgba(0,0,0,0.08);
    }

.ws-therapist-avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
}

.ws-therapist-info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.ws-therapist-name {
    margin: 0 0 0.2rem 0;
    font-size: 1.1rem;
    font-weight: 600;
    color: #2f2f2f;
}

.ws-therapist-email,
.ws-therapist-phone {
    margin: 0;
    font-size: 0.9rem;
    color: #555;
    margin-bottom: 0.3rem;
}

/* Approval badges */
.ws-therapist-badges {
    margin-top: 0.3rem;
}

.ws-approval-badge {
    background-color: #c8e6c9;
    color: #2e7d32;
    padding: 0.3rem 0.6rem;
    border-radius: 12px;
    font-size: 0.75rem;
    margin-right: 0.5rem;
}

.ws-pending-badge {
    background-color: #fff3cd;
    color: #856404;
    padding: 0.3rem 0.6rem;
    border-radius: 12px;
    font-size: 0.75rem;
}

/* Rating */
.ws-therapist-rating {
    margin-top: 0.3rem;
    display: flex;
    align-items: center;
}

.ws-avg-label {
    margin-right: 0.25rem;
    font-size: 0.9rem;
    color: #2f2f2f;
}

.ws-rating-stars {
    display: inline-flex;
    margin-right: 0.25rem;
}

.ws-avg-value {
    font-size: 0.9rem;
    color: #666;
}

/* Modal */
.ws-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.45);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.ws-modal-content {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 1.5rem;
    width: 90%;
    max-width: 600px;
    position: relative;
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
    max-height: 90vh;
    overflow-y: auto;
    animation: modalFadeIn 0.3s ease;
}

@keyframes modalFadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.ws-modal-close {
    background: none;
    border: none;
    font-size: 1.5rem;
    line-height: 1;
    color: #999;
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
    transition: color 0.3s ease;
}

    .ws-modal-close:hover {
        color: #666;
    }

/* Availability list in modal */
.ws-availability-list {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: 1rem;
}

.ws-slot-item {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0.6rem;
    margin-bottom: 0.5rem;
    border-radius: 6px;
    background-color: #f4f6f8;
    transition: box-shadow 0.3s, background-color 0.3s;
    cursor: pointer;
}

    .ws-slot-item:hover {
        box-shadow: 0 3px 6px rgba(0,0,0,0.07);
        background-color: #eef1f5;
    }

.booked-slot {
    background-color: #ffe6e6 !important;
}

.selected {
    background-color: #c8e6c9 !important;
}

.ws-day {
    font-weight: 600;
    color: #2f2f2f;
}

.ws-time {
    font-size: 0.9rem;
    color: #555;
}

/* Booking actions */
.ws-booking-actions {
    margin-top: 1rem;
}

/* New button styles for different actions */
.ws-join-button {
    background: linear-gradient(135deg, #4caf50, #81c784);
    color: #fff;
    border: none;
    border-radius: 20px;
    padding: 0.55rem 1rem;
    cursor: pointer;
    transition: background 0.3s ease;
    font-size: 0.9rem;
}

    .ws-join-button:hover {
        background: linear-gradient(135deg, #43a047, #66bb6a);
    }

.ws-cancel-button {
    background: linear-gradient(135deg, #e57373, #ef9a9a);
    color: #fff;
    border: none;
    border-radius: 20px;
    padding: 0.55rem 1rem;
    cursor: pointer;
    transition: background 0.3s ease;
    font-size: 0.9rem;
}

    .ws-cancel-button:hover {
        background: linear-gradient(135deg, #ef5350, #e57373);
    }

.ws-book-button {
    background: linear-gradient(135deg, #64b5f6, #90caf9);
    color: #fff;
    border: none;
    border-radius: 20px;
    padding: 0.6rem 1.2rem;
    cursor: pointer;
    transition: background 0.3s ease, transform 0.2s ease;
    font-size: 0.9rem;
}

    .ws-book-button:hover {
        background: linear-gradient(135deg, #42a5f5, #64b5f6);
        transform: translateY(-2px);
    }

.ws-submit-rating {
    background: linear-gradient(135deg, #ffb74d, #ffcc80);
    color: #2f2f2f;
    border: none;
    border-radius: 20px;
    padding: 0.6rem 1.2rem;
    cursor: pointer;
    transition: background 0.3s ease, transform 0.2s ease;
    font-size: 0.9rem;
}

    .ws-submit-rating:hover {
        background: linear-gradient(135deg, #ffa726, #ffb74d);
        transform: translateY(-2px);
    }

/* Rating section */
.ws-rating-section {
    margin-top: 2rem;
}

.ws-stars {
    display: inline-flex;
    margin-bottom: 0.5rem;
}

.ws-star {
    cursor: pointer;
    transition: color 0.2s;
    margin-right: 4px;
}

/* My Bookings section */
.ws-mybookings {
    background-color: #ffffff;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.05);
}

.my-booking-card {
    background-color: #f9f9f9;
    border: 1px solid #eee;
    border-radius: 6px;
    padding: 1rem;
    margin-bottom: 1rem;
    transition: box-shadow 0.3s;
}

    .my-booking-card:hover {
        box-shadow: 0 3px 6px rgba(0,0,0,0.05);
    }

    .my-booking-card p {
        margin: 0.4rem 0;
    }

/* Profile Section */
.ws-profile-section {
    background-color: #ffffff;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.05);
}

.ws-profile-description {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 1rem;
}

    .ws-profile-description p {
        flex: 1;
        margin: 0;
        padding: 0.55rem;
        background-color: #f4f6f8;
        border: 1px solid #ccc;
        border-radius: 6px;
    }

    .ws-profile-description textarea {
        flex: 1;
    }

.ws-edit-icon {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.2rem;
}

/* Collapsible panel animation */
.collapsible-panel {
    animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* Notification emoji */
.emoji-bell {
    margin-left: 0.5rem;
    font-size: 1.2rem;
}

.ws-button-group {
    display: flex;
    gap: 1rem;
    align-items: center;
    margin-top: 0.8rem;
}

    /* Optional: Adjust common button styles if needed */
    .ws-button-group button {
        flex-shrink: 0;
    }

/* Responsive tweaks */
@media (max-width: 768px) {
    /* Header adjustments */
    .ws-header {
        flex-direction: column;
        align-items: center;
        gap: 0.75rem;
        padding: 1rem;
    }

    .ws-header-left h1 {
        font-size: 1.3rem;
        text-align: center;
    }

    .ws-header-nav {
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;
    }

    /* Layout adjustments */
    .ws-layout {
        flex-direction: column;
        gap: 1rem;
    }

    .ws-sidebar-left,
    .ws-main-content {
        width: 100%;
    }

    /* Filters adjustments */
    .ws-filters {
        width: 100%;
        flex-direction: column;
        gap: 0.75rem;
        padding: 0.75rem;
    }

    /* Therapist card adjustments */
    .ws-therapist-card {
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 0.75rem;
    }

    .ws-therapist-avatar {
        margin-bottom: 0.5rem;
    }

    .ws-therapist-info {
        width: 100%;
    }

    /* Navigation buttons - stack vertically */
    .ws-nav-btn {
        width: 100%;
        text-align: center;
    }
}

/* For devices with a width of 480px or less */
@media (max-width: 480px) {
    .ws-header {
        padding: 0.75rem 1rem;
    }

    .ws-header-left h1 {
        font-size: 1.2rem;
    }

    .ws-nav-btn {
        padding: 0.5rem 1rem;
        font-size: 0.85rem;
    }

    .ws-filters {
        padding: 0.5rem;
    }

    .ws-therapist-card {
        padding: 0.5rem;
    }
    /* Adjust text sizes if needed */
    .ws-therapist-name {
        font-size: 1rem;
    }
}