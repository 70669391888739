/* Scoped Styles for Registration Page */
.scoped-area .chat-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background: linear-gradient(120deg, #f0f4f8, #d9e4f5);
    box-sizing: border-box;
}

.scoped-area .header {
    width: 100%;
    max-width: 1100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    padding: 15px 20px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    border-radius: 8px;
    margin-bottom: 20px;
}

.scoped-area .user-info {
    display: flex;
    align-items: center;
    gap: 10px;
}

.scoped-area .user-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid #66a6ff;
    object-fit: cover;
}

.scoped-area .logout-btn {
    background-color: #ff5c5c;
    color: #fff;
    border: none;
    padding: 10px 16px;
    border-radius: 20px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: background-color 0.3s;
}

    .scoped-area .logout-btn:hover {
        background-color: #ff3b3b;
    }

.scoped-area .registration-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
    width: 100%;
    max-width: 1100px;
}

/* Registration Form (default is Helpseeker) */
.scoped-area .reg-form {
    flex: 1 1 400px;
    background: #fff;
    border-radius: 12px;
    padding: 30px 40px;
    box-shadow: 0 8px 16px rgba(0,0,0,0.1);
    min-width: 280px;
}

    .scoped-area .reg-form h3 {
        font-size: 1.8rem;
        text-align: center;
        margin-bottom: 25px;
        color: #333;
    }

.scoped-area .input-group {
    margin-bottom: 20px;
}

    .scoped-area .input-group label {
        display: block;
        font-size: 1rem;
        margin-bottom: 6px;
        color: #333;
    }

    .scoped-area .input-group input {
        width: 100%;
        padding: 12px 16px;
        font-size: 1rem;
        border: 2px solid #ddd;
        border-radius: 8px;
        transition: border-color 0.3s;
    }

        .scoped-area .input-group input:focus {
            border-color: #66a6ff;
            outline: none;
        }

.scoped-area .role-btn-group {
    text-align: center;
    margin-bottom: 20px;
}

    .scoped-area .role-btn-group p {
        font-size: 1.1rem;
        margin-bottom: 10px;
        color: #333;
    }

.scoped-area .role-btn {
    margin: 0 10px;
    padding: 12px 22px;
    background-color: #ddd;
    color: #333;
    border: none;
    border-radius: 30px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
}

    .scoped-area .role-btn.active,
    .scoped-area .role-btn:hover {
        background-color: #66a6ff;
        color: #fff;
    }

.scoped-area .submit-btn {
    width: 100%;
    padding: 16px;
    background-color: #66a6ff;
    color: #fff;
    border: none;
    border-radius: 30px;
    font-size: 1.1rem;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 10px;
}

    .scoped-area .submit-btn:hover {
        background-color: #5595ee;
    }

.scoped-area .error-message {
    color: #d32f2f;
    font-size: 0.95rem;
    text-align: center;
    margin-top: 15px;
}

/* Volunteer Sidebar */
.scoped-area .reg-sidebar {
    flex: 1 1 320px;
    background: #fff;
    border-radius: 12px;
    padding: 30px 20px;
    box-shadow: 0 8px 16px rgba(0,0,0,0.1);
    min-width: 280px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

    .scoped-area .reg-sidebar h2 {
        font-size: 1.6rem;
        margin-bottom: 15px;
        color: #333;
    }

    .scoped-area .reg-sidebar p {
        font-size: 1rem;
        margin-bottom: 25px;
        line-height: 1.5;
        color: #555;
    }

.scoped-area .volunteer-btn {
    display: inline-block;
    padding: 14px 24px;
    background-color: #66a6ff;
    color: #fff;
    text-decoration: none;
    border-radius: 30px;
    font-size: 1.1rem;
    transition: background-color 0.3s;
}

    .scoped-area .volunteer-btn:hover {
        background-color: #5595ee;
    }

/* Responsive adjustments */
@media (max-width: 768px) {
    .scoped-area .registration-wrapper {
        flex-direction: column;
        gap: 20px;
    }
    /* Order: form first, then volunteer sidebar */
    .scoped-area .reg-form {
        order: 1;
    }

    .scoped-area .reg-sidebar {
        order: 2;
    }
}
