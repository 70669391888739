/* Global Container & Animation */
.user-list-container {
    padding: 20px;
    background-color: #f9f9f9;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Header Styles */
.header {
    width: 100%;
    max-width: 600px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #1e90ff;
    color: #fff;
    padding: 10px 15px;
    border-radius: 8px 8px 0 0;
    margin-bottom: 20px;
}

    .header h1 {
        font-size: 24px;
        margin: 0;
        text-align: center;
        flex-grow: 1;
    }

/* Top Button Styles (Back & Logout) */
.top-button {
    background: none;
    border: none;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    padding: 8px 12px;
    transition: background-color 0.3s ease, transform 0.3s ease;
    border-radius: 4px;
}

    .top-button:hover {
        background-color: rgba(255, 255, 255, 0.2);
        transform: scale(1.05);
    }

/* Volunteer List Styles */
.user-list {
    width: 100%;
    max-width: 600px;
    list-style: none;
    padding: 0;
    margin: 0;
}

.user-item {
    display: flex;
    align-items: center;
    padding: 15px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    animation: fadeIn 0.5s ease-in-out;
}

    .user-item:hover {
        background-color: #f0f8ff;
        transform: translateY(-2px);
    }

    /* User Item Image & Details */
    .user-item img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        object-fit: cover;
        margin-right: 15px;
    }

.user-details {
    flex-grow: 1;
}

    .user-details h2 {
        margin: 0;
        font-size: 20px;
        color: #333;
    }

    .user-details p {
        margin: 5px 0 0;
        color: #666;
        font-size: 14px;
    }

/* Back to Kit Button */
.back-kit-button {
    margin-top: 20px;
    background-color: #1e90ff;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

    .back-kit-button:hover {
        background-color: #0f6fc0;
        transform: scale(1.05);
    }

/* Responsive Adjustments */
@media (max-width: 768px) {
    .header h1 {
        font-size: 20px;
    }

    .user-item {
        padding: 12px;
    }

    .user-details h2 {
        font-size: 18px;
    }
}

@media (max-width: 480px) {
    .header h1 {
        font-size: 18px;
    }

    .user-item {
        padding: 10px;
    }

    .user-details h2 {
        font-size: 16px;
    }
}
