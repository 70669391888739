/* main.css */

/* -- Modern Calming Palette --
   Primary Blue:       #4A90E2
   Secondary Teal:     #50E3C2
   Accent Orange:      #F5A623
   Text Dark Gray:     #333333
   Light Background:   #FFFFFF
   Dark Mode BG:       #121212
   Dark Mode Text:     #F0F0F0
*/

/* Global Styles */
body {
    font-family: 'Roboto', sans-serif;
    font-size: clamp(1rem, 1.5vw, 1.125rem);
    margin: 0;
    padding: 0;
    background: #FFFFFF;
    color: #333333;
    line-height: 1.6;
    transition: background-color 0.3s, color 0.3s;
    box-sizing: border-box;
}

.dark-mode {
    background: #121212;
    color: #F0F0F0;
}

.home-page-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

/* Navbar */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 50px; /* Increased padding for better spacing */
    background: linear-gradient(135deg, #4A90E2, #50E3C2);
    color: #FFFFFF;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Left Section (Logo & Hamburger) */
.navbar-left {
    flex: 1;
    display: flex;
    align-items: center;
}

.kit-title {
    margin-left: 25px; /* Increased space between the hamburger and title */
    font-size: 2.2rem; /* Slightly larger font size for prominence */
    font-weight: bold;
}

/* Center Navigation Menu */
.navbar-menu {
    flex: 3; /* More space for the navigation items */
    text-align: center;
}

    .navbar-menu ul {
        display: flex;
        justify-content: center;
        gap: 40px; /* Increased gap for better separation */
        list-style: none;
        margin: 0;
        padding: 0;
    }

        .navbar-menu ul li {
            cursor: pointer;
            position: relative;
            font-size: 1.1rem;
        }

/* Dropdown Styles for Services */
.services-dropdown {
    position: relative;
    cursor: pointer;
}

.dropdown-content {
    position: absolute;
    top: 100%;
    left: 0;
    background: #FFFFFF;
    color: #333333;
    min-width: 160px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.15);
    padding: 10px 0;
    z-index: 100;
}

    .dropdown-content li {
        padding: 8px 16px;
        cursor: pointer;
        font-size: 1rem;
    }

        .dropdown-content li:hover {
            background: #f0f0f0;
        }

/* Right Section (Actions) */
.navbar-actions {
    flex: 1;
    text-align: right;
}

.theme-toggle {
    padding: 10px 20px; /* Slightly larger button for a balanced look */
    font-size: 1rem;
}

/* Dark Mode Navbar */
.dark-mode .navbar {
    background: #2A2A2A;
}

.dark-mode .theme-toggle {
    background: #FFFFFF;
    color: #2A2A2A;
}

/* Hamburger Icon */
.hamburger {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
    margin-right: 10px;
    cursor: pointer;
}

    .hamburger .bar {
        width: 28px;
        height: 3px;
        background-color: #FFFFFF;
        margin: 5px 0;
        transition: all 0.3s ease;
    }

/* Mobile Menu Styles */
.mobile-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 70%;
    max-width: 260px;
    height: 100%;
    background: #FFFFFF;
    padding: 20px;
    transform: translateX(-100%);
    transition: transform 0.3s ease;
    z-index: 1000;
    box-shadow: 2px 0 5px rgba(0,0,0,0.1);
}

    .mobile-menu.open {
        transform: translateX(0);
    }

    .mobile-menu ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

        .mobile-menu ul li {
            padding: 15px 0;
            border-bottom: 1px solid #DDD;
            cursor: pointer;
            font-size: 1rem;
            color: #333333;
        }

/* "Need Help Now?" floating banner */
.help-now-banner {
    position: fixed;
    top: 80px;
    right: 20px;
    background-color: #F5A623;
    color: #FFFFFF;
    padding: 10px 20px;
    border-radius: 5px;
    font-weight: bold;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    z-index: 9999;
    transition: background 0.3s ease;
}

    .help-now-banner:hover {
        background-color: #e5941f;
    }

.dark-mode .help-now-banner {
    background-color: #F5A623;
}

/* Sticky Chat Button */
.floating-chat {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background: linear-gradient(45deg, #4A90E2, #50E3C2);
    color: #FFFFFF;
    font-weight: bold;
    padding: 14px 24px;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0,0,0,0.15);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    z-index: 9999;
}

    .floating-chat:hover {
        transform: translateY(-4px);
        box-shadow: 0 8px 16px rgba(0,0,0,0.2);
    }

.dark-mode .floating-chat {
    background: linear-gradient(45deg, #333333, #555555);
    color: #F0F0F0;
}

/* Footer */
.footer {
    text-align: center;
    padding: 20px;
    background: linear-gradient(to right, #4A90E2, #50E3C2);
    color: #FFFFFF;
    font-size: 1rem;
    box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.1);
    margin-top: auto;
}

    .footer a {
        color: #F5A623;
        text-decoration: underline;
        transition: color 0.3s;
    }

        .footer a:hover {
            color: #e5941f;
        }

/* Buttons */
.button {
    padding: 14px 28px;
    font-size: 1.1rem;
    font-weight: bold;
    text-transform: uppercase;
    border: none;
    border-radius: 50px;
    background: linear-gradient(135deg, #4A90E2, #50E3C2);
    color: #ffffff;
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease, background 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    outline: none;
    display: inline-block;
}

    .button:hover {
        transform: translateY(-3px);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
        background: linear-gradient(135deg, #50E3C2, #4A90E2);
    }

    .button:active {
        transform: translateY(0);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

.dark-mode .button {
    background: linear-gradient(135deg, #333333, #555555);
    color: #F0F0F0;
    box-shadow: 0 4px 6px rgba(255, 255, 255, 0.1);
}

.button:focus {
    box-shadow: 0 0 8px rgba(74, 144, 226, 0.8);
}


/* Responsive Design */

/* Show hamburger and hide desktop menu on mobile */
@media (max-width: 768px) {
    .navbar {
        padding: 8px 12px;
    }

        .navbar h1, .kit-title {
            font-size: 1.5rem;
        }

    .navbar-menu {
        display: none;
    }

    .hamburger {
        display: flex;
    }

    .footer {
        font-size: 0.9rem;
    }
}

/* Hide mobile menu on desktop */
@media (min-width: 769px) {
    .mobile-menu {
        display: none;
    }
}

@media (max-width: 992px) {
    .dashboard-container,
    .ws-dashboard-container {
        width: 95%;
        margin: 1rem auto;
    }
}

@media (max-width: 768px) {
    .dashboard-container,
    .ws-dashboard-container {
        padding: 1rem;
        margin: 1rem auto;
    }

        .dashboard-container > header,
        .ws-header {
            flex-direction: column;
            align-items: flex-start;
        }

            .dashboard-container > header h1,
            .ws-header h1 {
                font-size: 1.5rem;
                margin-bottom: 0.5rem;
            }

            .dashboard-container > header button,
            .ws-logout-button {
                margin-top: 0.5rem;
            }

        .dashboard-container section input,
        .dashboard-container section textarea,
        .dashboard-container section button,
        .ws-dashboard-container .ws-filter-group input,
        .ws-dashboard-container .ws-filter-group select,
        .ws-dashboard-container .ws-book-button,
        .ws-dashboard-container .ws-submit-rating {
            max-width: 100% !important;
            width: 100%;
        }
}

@media (max-width: 576px) {
    .dashboard-container,
    .ws-dashboard-container {
        margin: 0.5rem auto;
        padding: 0.5rem;
    }

        .dashboard-container section h2,
        .ws-therapist-list h2 {
            font-size: 1.3rem;
        }

    .ws-therapist-card {
        flex-direction: column;
        align-items: flex-start;
    }
}
