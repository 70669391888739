/* chat.css - Updated with centralized variables and improved responsiveness */

/* Chat Container */
.chat-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    max-width: 600px;
    margin: 0 auto;
    background: linear-gradient(120deg, #f0f4f8, #d9e4f5);
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
}

/* Chat Header */
.chat-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--primary-blue);
    color: var(--light-bg);
    padding: 10px 15px;
    border-radius: 10px 10px 0 0;
}

    .chat-header h1 {
        flex-grow: 1;
        text-align: center;
        font-size: 1.5rem;
        margin: 0;
    }

/* Top Button */
.top-button {
    background: none;
    border: none;
    color: var(--light-bg);
    font-size: 16px;
    cursor: pointer;
    transition: background-color var(--transition-fast), transform 0.2s ease;
}

    .top-button:hover {
        background-color: rgba(255, 255, 255, 0.2);
        transform: scale(1.05);
    }

/* Chat Box */
.chat-box {
    flex: 1;
    overflow-y: auto;
    padding: 10px;
    background-color: var(--light-bg);
    border: 1px solid #ddd;
    border-radius: 0 0 10px 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    box-sizing: border-box;
    scroll-behavior: smooth;
}

/* Messages */
.message {
    max-width: 70%;
    padding: 10px 15px;
    border-radius: 20px;
    font-size: 0.95rem;
    line-height: 1.4;
    word-wrap: break-word;
    position: relative;
    animation: fadeIn 0.3s ease-in-out;
}

.sent {
    align-self: flex-end;
    background: #daf8e3;
    color: #2b7a0b;
    border-bottom-right-radius: 0;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.received {
    align-self: flex-start;
    background: #f1f1f1;
    color: var(--text-dark);
    border-bottom-left-radius: 0;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.message p {
    margin: 0;
    padding-bottom: 5px;
}

.message::after {
    content: attr(data-timestamp);
    display: block;
    font-size: 0.75rem;
    text-align: right;
    color: #aaa;
    margin-top: 5px;
}

/* Input Container */
.input-container {
    display: flex;
    align-items: center;
    padding: 10px;
    gap: 10px;
    box-sizing: border-box;
    background-color: var(--light-bg);
    border-top: 1px solid #ddd;
}

    .input-container textarea {
        flex: 1;
        resize: none;
        overflow: hidden;
        padding: 8px 10px;
        font-size: 14px;
        line-height: 1.5;
        border: 1px solid #ccc;
        border-radius: 20px;
        box-sizing: border-box;
        min-height: 40px;
        max-height: 150px;
    }

.send-button {
    padding: 10px 20px;
    background-color: var(--primary-blue);
    color: var(--light-bg);
    border: none;
    border-radius: 20px;
    font-size: 1rem;
    cursor: pointer;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color var(--transition-fast);
}

    .send-button:hover {
        background-color: #0056b3;
    }

/* Scrollbar Styling */
.chat-box::-webkit-scrollbar {
    width: 8px;
}

.chat-box::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 4px;
}

    .chat-box::-webkit-scrollbar-thumb:hover {
        background: #bbb;
    }

/* Responsive Adjustments */
@media (max-width: 768px) {
    .chat-container {
        padding: 15px;
        height: auto;
    }

    .chat-header h1 {
        font-size: 1.25rem;
    }

    .message {
        font-size: 0.9rem;
        padding: 8px 12px;
    }

    .input-container textarea {
        padding: 8px;
        font-size: 0.95rem;
    }

    .send-button {
        padding: 8px 15px;
        font-size: 0.95rem;
    }
}

@media (max-width: 480px) {
    .chat-container {
        padding: 10px;
    }

    .chat-header h1 {
        font-size: 1rem;
    }

    .message {
        font-size: 0.85rem;
        padding: 6px 10px;
    }

    .input-container textarea {
        padding: 6px;
        font-size: 0.9rem;
    }

    .send-button {
        padding: 6px 12px;
        font-size: 0.9rem;
    }
}

/* Animations */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}
