/* AboutUs.css */

/* Base Styles */
.aboutus-container {
    font-family: 'Roboto', sans-serif;
    padding: 20px;
    background: #f7f9fc;
    color: #2f2f2f;
}

.aboutus-hero {
    text-align: center;
    padding: 50px 20px;
    background: linear-gradient(135deg, #aee6ac 0%, #98ddca 100%);
    animation: slideDown 1s ease-out forwards;
}

.aboutus-title {
    font-size: 3rem;
    margin-bottom: 10px;
    opacity: 0;
    animation: fadeIn 1s 0.5s forwards;
}

.aboutus-subtitle {
    font-size: 1.5rem;
    margin-bottom: 30px;
    opacity: 0;
    animation: fadeIn 1s 1s forwards;
}

.aboutus-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 20px;
    margin-top: 30px;
    animation: fadeIn 1.5s ease-out forwards;
}

.aboutus-card {
    background: #ffffff;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    border-radius: 10px;
    padding: 20px;
    flex: 1 1 300px;
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 1s forwards;
}

    .aboutus-card h2 {
        font-size: 2rem;
        margin-bottom: 10px;
    }

    .aboutus-card p,
    .aboutus-card ul {
        font-size: 1rem;
        line-height: 1.5;
    }

    .aboutus-card ul {
        list-style: none;
        padding: 0;
    }

        .aboutus-card ul li {
            padding: 5px 0;
            position: relative;
        }

            .aboutus-card ul li:before {
                content: '✔';
                margin-right: 8px;
                color: #98ddca;
            }

/* Animations */
@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideDown {
    from {
        transform: translateY(-100%);
    }

    to {
        transform: translateY(0);
    }
}

/* Staggering animations for the cards */
.aboutus-card:nth-child(1) {
    animation-delay: 0.5s;
}

.aboutus-card:nth-child(2) {
    animation-delay: 0.7s;
}

.aboutus-card:nth-child(3) {
    animation-delay: 0.9s;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .aboutus-hero {
        padding: 30px 10px;
    }

    .aboutus-title {
        font-size: 2.5rem;
    }

    .aboutus-subtitle {
        font-size: 1.2rem;
    }

    .aboutus-content {
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
    }

    .aboutus-card {
        width: 90%;
        margin-bottom: 20px;
        padding: 15px;
    }
}

@media (max-width: 480px) {
    .aboutus-title {
        font-size: 2rem;
    }

    .aboutus-subtitle {
        font-size: 1rem;
    }

    .aboutus-card h2 {
        font-size: 1.5rem;
    }

    .aboutus-card p,
    .aboutus-card ul {
        font-size: 0.9rem;
    }
}
