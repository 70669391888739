/* assessment.css - Redesigned Assessment CSS for a Modern Look and Optimized UI */

/* Container */
.assessment-container {
    max-width: 800px;
    margin: 50px auto;
    padding: 30px;
    background: #ffffff;
    border-radius: 15px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    text-align: center;
    overflow: hidden;
}

@media (max-width: 768px) {
    .assessment-container {
        padding: 20px;
        margin: 20px;
        border-radius: 10px;
    }
}

/* Assessment Header */
.assessment-header {
    font-size: 2em;
    color: #34495e;
    margin-bottom: 20px;
}

/* Error Message */
.error {
    color: red;
    margin: 10px 0;
    font-weight: bold;
}

/* Name Form */
.name-form h2 {
    font-size: 1.8em;
    color: #34495e;
    margin-bottom: 20px;
}

.name-form input {
    margin-top: 15px;
    padding: 12px 18px;
    font-size: 1.1em;
    width: 100%;
    max-width: 400px;
    border: 2px solid #dcdcdc;
    border-radius: 10px;
    background-color: #f9f9f9;
    transition: box-shadow 0.3s ease, border-color 0.3s ease;
}

    .name-form input:focus {
        border-color: #3f51b5;
        background-color: #f1f9ff;
        box-shadow: 0 0 8px rgba(63, 81, 181, 0.3);
    }

.name-form button {
    margin-top: 20px;
    padding: 12px 20px;
    font-size: 1.1em;
    font-weight: bold;
    color: #ffffff;
    background: linear-gradient(to right, #42a5f5, #3f51b5);
    border: none;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 0 6px 15px rgba(63, 81, 181, 0.2);
    transition: transform 0.3s ease, background 0.3s ease;
}

    .name-form button:hover {
        background: linear-gradient(to right, #3f51b5, #42a5f5);
        transform: translateY(-3px);
    }

/* Assessment Form */
.assessment-form {
    margin-top: 30px;
}

/* Question Styles */
.question {
    margin-bottom: 15px;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
}

    .question:hover {
        transform: scale(1.02);
        box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
    }

    .question p {
        font-size: 1.2em;
        color: #34495e;
        font-weight: bold;
        margin-bottom: 15px;
        text-align: left;
    }

/* Options Section */
.options {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: center;
}

@media (max-width: 768px) {
    .options {
        flex-direction: column;
        align-items: stretch;
        gap: 10px;
    }
}

.option-label {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 16px;
    font-size: 1em;
    font-weight: bold;
    color: #34495e;
    background: #f9f9f9;
    border: 2px solid #ddd;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
}

    .option-label:hover {
        background-color: #f1f9ff;
        border-color: #3f51b5;
        transform: scale(1.05);
        box-shadow: 0 4px 15px rgba(63, 81, 181, 0.2);
    }

    .option-label input {
        display: none;
    }

    .option-label span {
        padding: 8px;
        width: 100%;
        border-radius: 8px;
    }

    .option-label input:checked + span {
        background: linear-gradient(90deg, #42a5f5, #3f51b5);
        color: white;
        border: none;
        box-shadow: 0 4px 10px rgba(63, 81, 181, 0.3);
    }

/* Progress Bars */
.progress-bar {
    position: sticky;
    top: 0;
    z-index: 10;
    background: #f7f9fc;
    padding: 5px 0;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.vertical-progress-bar {
    position: fixed;
    left: 10px;
    top: 10%;
    width: 30px;
    height: 80%;
    background: #e0e0e0;
    border-radius: 15px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

.progress-fill {
    width: 100%;
    background: linear-gradient(180deg, #4caf50, #8bc34a);
    text-align: center;
    font-size: 0.8rem;
    font-weight: bold;
    color: white;
    border-radius: 15px 15px 0 0;
    transition: height 0.4s ease-in-out;
}

/* Result Progress Bar */
.result-progress-bar {
    height: 20px;
    background: #e0e0e0;
    border-radius: 10px;
    overflow: hidden;
    margin: 10px 0;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
}

    .result-progress-bar .result-progress-fill {
        height: 100%;
        width: 0;
        border-radius: 10px;
        text-align: center;
        color: white;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: width 0.4s ease-in-out, background-color 0.4s ease-in-out;
    }

@media (max-width: 768px) {
    .progress-bar {
        height: 15px;
    }

    .progress-fill {
        font-size: 0.8rem;
    }

    .option-label {
        padding: 10px 12px;
    }
}
