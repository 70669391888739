/* modernJournaling.css */

/* CSS Variables for customization */
:root {
    --primary-color: #7b42f6;
    --primary-hover: #6a32c5;
    --secondary-color: #fff;
    --text-color: #333;
    --font-primary: "Roboto", sans-serif;
    --font-script: "Dancing Script", cursive;
    --background-neutral: linear-gradient(to bottom, #d7d2cc, #304352);
    --background-happy: linear-gradient(to bottom, #ffe47a, #fcd757);
    --background-sad: linear-gradient(to bottom, #83a4d4, #b6fbff);
    --background-stressed: linear-gradient(to bottom, #ff6f61, #de6262);
}

/* Reset defaults */
* {
    box-sizing: border-box;
}

/* Overall journaling page */
.modern-journaling-page {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-primary);
    padding: 20px;
}

/* Mood-based backgrounds */
body.modern-mood-neutral {
    background: var(--background-neutral);
}

body.modern-mood-happy {
    background: var(--background-happy);
}

body.modern-mood-sad {
    background: var(--background-sad);
}

body.modern-mood-stressed {
    background: var(--background-stressed);
}

/* Main container */
.modern-container {
    background: var(--secondary-color);
    width: 100%;
    max-width: 700px;
    margin: auto;
    border-radius: 15px;
    padding: 40px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
    animation: fadeInScale 0.6s ease-out;
}

/* Fade in effect */
@keyframes fadeInScale {
    from {
        opacity: 0;
        transform: scale(0.95);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}

/* Top Navigation Bar */
.modern-navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
}

.modern-brand {
    font-size: 1.8rem;
    font-family: var(--font-script);
    color: var(--text-color);
}

.modern-nav-buttons {
    display: flex;
    gap: 10px;
}

/* Page title */
.modern-page-title {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 30px;
    color: var(--text-color);
    font-family: var(--font-script);
}

/* Not authenticated */
.modern-not-authenticated {
    text-align: center;
    padding: 20px;
}

/* Journal content layout */
.modern-journal-content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

/* Header styling (inside journal view) */
.modern-journal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modern-greeting {
    font-size: 1.8rem;
    color: var(--text-color);
}

/* Button styling */
.modern-button {
    background: var(--primary-color);
    color: #fff;
    border: none;
    border-radius: 8px;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.3s ease, transform 0.2s ease;
}

    .modern-button:hover {
        background: var(--primary-hover);
    }

/* Entry section */
.modern-entry-section {
    background: #fafafa;
    border-radius: 15px;
    padding: 25px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    position: relative;
}

/* Emoji picker */
.modern-emoji-picker {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
}

.modern-emoji-button {
    background: none;
    border: 2px solid transparent;
    font-size: 2.5rem;
    cursor: pointer;
    transition: transform 0.2s ease, border-color 0.2s ease;
    padding: 6px;
}

    .modern-emoji-button:hover {
        transform: scale(1.15);
    }

    .modern-emoji-button.selected {
        border-color: var(--primary-color);
        border-radius: 50%;
    }

/* Textarea styling */
.modern-entry-textarea {
    width: 100%;
    padding: 15px;
    font-size: 1.1rem;
    border: 2px solid #ddd;
    border-radius: 10px;
    resize: vertical;
    transition: border 0.3s ease, box-shadow 0.3s ease;
    font-family: var(--font-script);
}

    .modern-entry-textarea:focus {
        outline: none;
        border-color: var(--primary-color);
        box-shadow: 0 0 10px rgba(123, 66, 246, 0.3);
        background: #fff;
    }
    /* Lined paper effect */
    .modern-entry-textarea.lined-paper {
        background: repeating-linear-gradient( to bottom, transparent, transparent 30px, #e0e0e0 31px, #e0e0e0 32px );
        padding: 15px;
        border: 2px solid #ddd;
        border-radius: 10px;
        font-family: var(--font-script);
        font-size: 1.1rem;
        resize: vertical;
        transition: border 0.3s ease, box-shadow 0.3s ease;
    }

/* Save button spacing */
.modern-button.save-button {
    margin-top: 15px;
}

/* Past entries title */
.modern-past-entries-title {
    font-size: 1.8rem;
    color: var(--text-color);
    margin-bottom: 15px;
    text-align: left;
}

/* Past entries list */
.modern-entries-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

/* Entry item */
.modern-entry-item {
    background: #fff;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
    transition: transform 0.3s, box-shadow 0.3s;
}

    .modern-entry-item:hover {
        transform: translateY(-3px);
        box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
    }

/* Entry text and meta */
.modern-entry-text {
    margin: 0;
    font-size: 1.1rem;
    line-height: 1.6;
    color: var(--text-color);
    white-space: pre-wrap;
}

.modern-entry-meta {
    display: block;
    margin-top: 8px;
    font-size: 0.9rem;
    color: #888;
}

/* Loading message */
.modern-loading-message {
    text-align: center;
    margin: 20px 0;
    font-size: 1.1rem;
    color: #666;
}

/* Back/Edit Icon Button */
.modern-back-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.8rem;
    cursor: pointer;
    color: var(--primary-color);
    transition: transform 0.2s ease;
}

    .modern-back-button:hover {
        transform: scale(1.1);
    }

/* Responsive adjustments for tablets */
@media (max-width: 768px) {
    .modern-container {
        padding: 20px;
        max-width: 90%;
    }

    .modern-page-title {
        font-size: 2rem;
    }

    .modern-greeting {
        font-size: 1.6rem;
    }

    .modern-entry-textarea {
        font-size: 1rem;
    }

    .modern-button {
        padding: 8px 16px;
        font-size: 0.9rem;
    }
}

/* Responsive adjustments for mobile devices */
@media (max-width: 480px) {
    .modern-container {
        padding: 10px;
        max-width: 100%;
    }

    .modern-navbar {
        flex-direction: column;
        align-items: flex-start;
    }

    .modern-brand {
        font-size: 1.6rem;
        margin-bottom: 10px;
    }

    .modern-nav-buttons {
        width: 100%;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
    }

    .modern-page-title {
        font-size: 1.8rem;
        margin-bottom: 20px;
    }

    .modern-greeting {
        font-size: 1.4rem;
    }

    .modern-entry-textarea {
        font-size: 0.85rem;
    }

    .modern-button {
        padding: 6px 10px;
        font-size: 0.8rem;
        margin-bottom: 5px;
    }

    .modern-emoji-button {
        font-size: 1.8rem;
        padding: 4px;
    }

    .modern-entry-section {
        padding: 15px;
    }
}
