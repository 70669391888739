/* therapistdashboard.css */

/* -- Modern Pastel Palette --
   Primary Soft Green:    #b2ddb6
   Secondary Mint:        #aee6ac
   Accent Soft Teal:      #98ddca
   Text Dark Gray:        #2f2f2f
   Light Gray BG:         #f9fafc
   Error Red:             #d32f2f
   Card BG:               #ffffff
*/

/* Overall container for the dashboard page */
.dashboard-container {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    background-color: #f9fafc;
    font-family: 'Roboto', sans-serif;
    color: #2f2f2f;
    min-height: 100vh;
    box-sizing: border-box;
}

    /* Header bar */
    .dashboard-container > header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: linear-gradient(135deg, #b2ddb6, #aee6ac);
        padding: 1rem 1.5rem;
        border-radius: 8px;
        margin-bottom: 2rem;
    }

        .dashboard-container > header h1 {
            margin: 0;
            font-size: 1.6rem;
            font-weight: 600;
            color: #2f2f2f;
        }

        .dashboard-container > header button {
            background-color: #ffffff;
            border: none;
            border-radius: 20px;
            padding: 0.5rem 1rem;
            cursor: pointer;
            font-size: 0.9rem;
            font-weight: 500;
            color: #2f2f2f;
            transition: background-color 0.3s ease, box-shadow 0.3s ease;
        }

            .dashboard-container > header button:hover {
                background-color: #98ddca;
                box-shadow: 0 4px 8px rgba(0,0,0,0.1);
            }

/* Error message styling */
.error-message {
    color: #d32f2f;
    background-color: #ffebee;
    padding: 0.75rem 1rem;
    border-left: 4px solid #d32f2f;
    margin-bottom: 1.5rem;
    border-radius: 4px;
}

/* Tab Navigation */
.tab-navigation {
    display: flex;
    gap: 1rem;
    margin-bottom: 2rem;
}

    .tab-navigation button {
        background: linear-gradient(135deg, #b2ddb6, #aee6ac);
        color: #2f2f2f;
        font-weight: 600;
        border: none;
        border-radius: 20px;
        padding: 0.55rem 1.25rem;
        cursor: pointer;
        transition: box-shadow 0.3s ease, transform 0.2s ease;
        font-size: 0.95rem;
    }

        .tab-navigation button.active {
            background-color: #98ddca;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        }

        .tab-navigation button:hover {
            box-shadow: 0 4px 8px rgba(0,0,0,0.12);
            transform: translateY(-2px);
        }

/* Section styling */
.dashboard-container section {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 1.5rem;
    margin-bottom: 2rem;
    box-shadow: 0 3px 6px rgba(0,0,0,0.05);
}

    .dashboard-container section h2 {
        margin-top: 0;
        font-size: 1.3rem;
        margin-bottom: 1rem;
        color: #2f2f2f;
    }

/* Labels & inputs */
.dashboard-container label {
    font-weight: 500;
    color: #2f2f2f;
    display: block;
    margin-bottom: 0.4rem;
}

.dashboard-container textarea,
.dashboard-container input[type="text"],
.dashboard-container input[type="date"],
.dashboard-container input[type="time"] {
    width: 100%;
    max-width: 400px;
    background-color: #f4f6f8;
    border: 1px solid #ccc;
    border-radius: 6px;
    padding: 0.55rem;
    font-size: 0.95rem;
    color: #2f2f2f;
    margin-bottom: 1rem;
    transition: box-shadow 0.2s ease;
}

    .dashboard-container textarea:focus,
    .dashboard-container input[type="text"]:focus,
    .dashboard-container input[type="date"]:focus,
    .dashboard-container input[type="time"]:focus {
        outline: none;
        border-color: #aee6ac;
        box-shadow: 0 0 0 3px rgba(174,230,172,0.2);
    }

/* Checkboxes */
.dashboard-container .checkbox-group {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

.checkbox-group label {
    display: flex;
    align-items: center;
    gap: 0.4rem;
    cursor: pointer;
}

.dashboard-container input[type="checkbox"] {
    width: 16px;
    height: 16px;
    margin: 0;
    cursor: pointer;
}

/* Buttons */
.dashboard-container button,
.dashboard-container .btn {
    background: linear-gradient(135deg, #b2ddb6, #aee6ac);
    color: #2f2f2f;
    font-weight: 600;
    border: none;
    border-radius: 20px;
    padding: 0.55rem 1.25rem;
    cursor: pointer;
    transition: box-shadow 0.3s ease, transform 0.2s ease;
    font-size: 0.95rem;
    margin-right: 0.5rem;
}

    .dashboard-container button:hover,
    .dashboard-container .btn:hover {
        box-shadow: 0 4px 8px rgba(0,0,0,0.12);
        transform: translateY(-2px);
    }

    .dashboard-container button:active,
    .dashboard-container .btn:active {
        transform: translateY(0px);
    }

/* Availability List */
.availability-list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    margin-top: 1rem;
    list-style: none;
    padding: 0;
}

.availability-card {
    background-color: #f9f9f9;
    border: 1px solid #eee;
    border-radius: 6px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    transition: box-shadow 0.3s;
}

    .availability-card:hover {
        box-shadow: 0 3px 6px rgba(0,0,0,0.05);
    }

    .availability-card strong {
        font-size: 1rem;
    }

/* Booked vs. free labels */
.booked-label {
    color: #d32f2f;
    font-weight: 600;
    margin-left: 0.5rem;
}

.free-label {
    color: #388e3c;
    font-weight: 600;
    margin-left: 0.5rem;
}

/* Bookings section */
.booking-item {
    background-color: #f9f9f9;
    border: 1px solid #eee;
    border-radius: 6px;
    padding: 1rem;
    margin-bottom: 1rem;
    transition: box-shadow 0.3s;
}

    .booking-item:hover {
        box-shadow: 0 3px 6px rgba(0,0,0,0.05);
    }

    .booking-item p {
        margin: 0.4rem 0;
    }

/* Profile Description styling */
.profile-description {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 1rem;
}

    .profile-description p {
        flex: 1;
        margin: 0;
        padding: 0.55rem;
        background-color: #f4f6f8;
        border: 1px solid #ccc;
        border-radius: 6px;
    }

    .profile-description textarea {
        flex: 1;
    }

/* Edit icon button */
.edit-icon {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.2rem;
}

/* For narrower screens */
@media (max-width: 768px) {
    .dashboard-container {
        padding: 1rem;
    }

        .dashboard-container > header {
            flex-direction: column;
            align-items: flex-start;
            gap: 0.75rem;
        }

            .dashboard-container > header h1 {
                font-size: 1.3rem;
            }

        .dashboard-container section {
            padding: 1rem;
        }

        .dashboard-container button {
            font-size: 0.85rem;
            border-radius: 16px;
        }
}
