/* home.css */

/* -- Modern Calming Palette --
   Primary Blue:       #4A90E2
   Secondary Teal:     #50E3C2
   Accent Orange:      #F5A623
   Text Dark Gray:     #333333
   Light Background:   #FFFFFF
   Dark Mode BG:       #121212
   Dark Mode Text:     #F0F0F0
*/

/* Hero Section */
.hero {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 50vh;
    background: linear-gradient(135deg, #4A90E2 0%, #50E3C2 100%);
    padding: 60px 20px;
    transition: background 0.5s ease;
}

.hero-heading {
    font-size: clamp(2.5rem, 5vw, 3.5rem);
    color: #FFFFFF;
    margin-bottom: 15px;
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    text-shadow: 1px 1px 3px rgba(0,0,0,0.2);
}

.hero-emoji {
    animation: pulse 2s infinite ease-in-out;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}

.hero-subtitle {
    font-size: 1.2rem;
    color: #F0F0F0;
    opacity: 0.9;
    margin-bottom: 30px;
}

/* Call-to-action Button in Hero */
.cta-button {
    padding: 16px 32px;
    font-size: 1.2rem;
    border: none;
    border-radius: 30px;
    background: linear-gradient(45deg, #4A90E2, #50E3C2);
    color: #FFFFFF;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    transition: transform 0.3s, box-shadow 0.3s;
}

    .cta-button:hover {
        transform: translateY(-4px);
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    }

/* Features Section */
.features {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 30px;
    padding: 50px 20px;
    background: #F8F8F8;
}

.feature {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px;
    border-radius: 12px;
    background: #FFFFFF;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s;
    cursor: pointer;
}

    .feature:hover {
        transform: translateY(-5px);
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
    }

.feature-icon {
    font-size: 3rem;
    margin-bottom: 15px;
    transition: transform 0.3s;
    color: #4A90E2;
}

.feature:hover .feature-icon {
    transform: scale(1.15);
}

.feature-title {
    font-size: 1.3rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333333;
}

.feature-description {
    font-size: 1rem;
    color: #666666;
}

/* Testimonials Section */
.testimonials {
    padding: 50px 20px;
    text-align: center;
    background: #EFEFEF;
}

.testimonials-title {
    font-size: 2rem;
    margin-bottom: 30px;
    color: #333333;
}

.testimonial-item {
    max-width: 700px;
    margin: 0 auto 30px auto;
    background: #FFFFFF;
    padding: 25px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0,0,0,0.1);
}

.testimonial-text {
    font-size: 1.1rem;
    color: #555555;
    margin-bottom: 10px;
    font-style: italic;
}

.testimonial-author {
    font-size: 0.95rem;
    color: #888888;
}

.services-dropdown {
    position: relative;
    cursor: pointer;
}

    .services-dropdown .dropdown-content {
        position: absolute;
        top: 100%;
        left: 0;
        background-color: #fff;
        min-width: 200px;
        display: flex;
        flex-direction: column;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        z-index: 1000;
    }

        .services-dropdown .dropdown-content li {
            padding: 10px 15px;
            white-space: nowrap;
            color: #333;
            transition: background 0.3s;
        }

            .services-dropdown .dropdown-content li:hover {
                background-color: #f0f0f0;
            }
/* Dark Mode Tweaks for Home Specific Sections */
.dark-mode .hero {
    background: linear-gradient(135deg, #1F1F1F 0%, #333333 100%);
}

.dark-mode .hero-heading {
    color: #F0F0F0;
}

.dark-mode .hero-subtitle {
    color: #CCCCCC;
}

.dark-mode .features {
    background: #1E1E1E;
}

.dark-mode .feature {
    background: #2A2A2A;
    color: #CCCCCC;
}

.dark-mode .feature-icon {
    color: #50E3C2;
}

.dark-mode .testimonials {
    background: #2A2A2A;
}

.dark-mode .testimonials-title {
    color: #E0E0E0;
}

.dark-mode .testimonial-item {
    background: #3A3A3A;
}

.dark-mode .testimonial-text {
    color: #DDDDDD;
}

.dark-mode .testimonial-author {
    color: #AAAAAA;
}
